"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StorageService = void 0;
var api_variables_const_1 = require("../const/api-variables.const");
var StorageService = /** @class */ (function () {
    function StorageService() {
        this.authStorageName = 'auth';
        this.facilityIdStorageName = 'facilityId';
        this.customerIdStorageName = 'customerId';
    }
    // returns customerId
    StorageService.prototype.parseUrlParams = function () {
        var urlParams = new URLSearchParams(location.search);
        var facilityId = urlParams.get(api_variables_const_1.FACILITY_ID);
        var auth = urlParams.get(api_variables_const_1.AUTH_PARAM);
        var customerIdStr = urlParams.get(api_variables_const_1.CUSTOMER_ID);
        if (facilityId)
            localStorage.setItem(this.facilityIdStorageName, JSON.stringify(facilityId));
        if (auth)
            localStorage.setItem(this.authStorageName, JSON.stringify(auth));
        if (customerIdStr) {
            var customerId = Number.parseInt(customerIdStr);
            localStorage.setItem(this.customerIdStorageName, JSON.stringify(customerId));
            return customerId;
        }
        else {
            return JSON.parse(localStorage.getItem(this.customerIdStorageName));
        }
    };
    StorageService.prototype.getAuthHeaders = function () {
        return {
            'app-auth': JSON.parse(localStorage.getItem(this.authStorageName)),
        };
    };
    StorageService.prototype.getFacilityId = function () {
        return JSON.parse(localStorage.getItem(this.facilityIdStorageName));
    };
    return StorageService;
}());
exports.StorageService = StorageService;
