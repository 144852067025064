"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetEndpoint = exports.PostEndpoint = void 0;
var PostEndpoint;
(function (PostEndpoint) {
    PostEndpoint["CustomerAmendment"] = "/customer-amendment";
    PostEndpoint["ContractCancelation"] = "/cancel-contract";
})(PostEndpoint = exports.PostEndpoint || (exports.PostEndpoint = {}));
var GetEndpoint;
(function (GetEndpoint) {
    GetEndpoint["Contracts"] = "/contracts";
    GetEndpoint["Customer"] = "/customer";
})(GetEndpoint = exports.GetEndpoint || (exports.GetEndpoint = {}));
