"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports._FirstVisit = exports._UtmFields = void 0;
exports._UtmFields = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
];
exports._FirstVisit = 'first_visit';
