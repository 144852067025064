"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AthleticsApi = void 0;
var error_handling_1 = require("../error-handling");
var object_service_1 = require("../object-service");
var variables_const_1 = require("../variables.const");
var AthleticsApi = /** @class */ (function () {
    function AthleticsApi() {
        this.errorHandling = object_service_1.ObjectService.getInstance(error_handling_1.ErrorHandling);
    }
    AthleticsApi.prototype.getStudios = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, fetch(variables_const_1.athleticsUrl, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            tenantKey: variables_const_1._settings.tenantKey,
                            path: 'studios',
                            type: 'GET',
                        }),
                    })
                        .then(function (createResponse) { return __awaiter(_this, void 0, void 0, function () {
                        var athleticsStudios, _i, athleticsStudios_1, studio, studioTags;
                        var _a, _b, _c, _d;
                        return __generator(this, function (_e) {
                            switch (_e.label) {
                                case 0:
                                    if (!createResponse.ok) {
                                        return [2 /*return*/, Promise.reject(createResponse.json())];
                                    }
                                    return [4 /*yield*/, createResponse.json()];
                                case 1:
                                    athleticsStudios = (_e.sent());
                                    for (_i = 0, athleticsStudios_1 = athleticsStudios; _i < athleticsStudios_1.length; _i++) {
                                        studio = athleticsStudios_1[_i];
                                        if (((_a = studio === null || studio === void 0 ? void 0 : studio.studioTags) === null || _a === void 0 ? void 0 : _a.length) || ((_b = studio === null || studio === void 0 ? void 0 : studio.redirect) === null || _b === void 0 ? void 0 : _b.length)) {
                                            studioTags = {};
                                            if ((_c = studio === null || studio === void 0 ? void 0 : studio.studioTags) === null || _c === void 0 ? void 0 : _c.length) {
                                                studioTags.name = studio.studioTags;
                                            }
                                            // you can manage studio tag redirects from settings object
                                            if ((_d = studio === null || studio === void 0 ? void 0 : studio.redirect) === null || _d === void 0 ? void 0 : _d.length) {
                                                studioTags['redirect'] = studio.redirect;
                                            }
                                            studio.studioTags = [studioTags];
                                        }
                                    }
                                    return [2 /*return*/, athleticsStudios];
                            }
                        });
                    }); })
                        .catch(function (createdErr) { return __awaiter(_this, void 0, void 0, function () {
                        var error;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, createdErr];
                                case 1:
                                    error = _a.sent();
                                    if (error.message) {
                                        this.errorHandling.showErrorMessage(error.message);
                                    }
                                    throw error;
                            }
                        });
                    }); })];
            });
        });
    };
    AthleticsApi.prototype.createNewCustomer = function (body) {
        var _this = this;
        return fetch(variables_const_1.athleticsUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                tenantKey: variables_const_1._settings.tenantKey,
                location: body.studioId,
                path: 'members',
                type: 'POST',
                data: this.convertToAthleticsCustomer(body),
            }),
        })
            .then(function (createResponse) {
            if (!createResponse.ok) {
                return Promise.reject(createResponse.json());
            }
            return createResponse.json();
        })
            .then(function (created) {
            // Created response
            if (variables_const_1.environment === 'dev') {
                console.log('created', created);
            }
            // Write response in localstorage for later usage
            var dataArray = created;
            for (var key in dataArray) {
                if (dataArray[key]) {
                    localStorage.setItem(key, dataArray[key]);
                }
            }
            return created;
        })
            .catch(function (createdErr) { return __awaiter(_this, void 0, void 0, function () {
            var error;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, createdErr];
                    case 1:
                        error = _a.sent();
                        if (error.message) {
                            this.errorHandling.showErrorMessage(error.message);
                        }
                        throw error;
                }
            });
        }); });
    };
    AthleticsApi.prototype.getOfferings = function (studioId) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var res, body, athleticsBody, error, error_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 6, , 7]);
                        return [4 /*yield*/, fetch(variables_const_1.athleticsUrl, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify({
                                    tenantKey: variables_const_1._settings.tenantKey,
                                    location: studioId,
                                    path: 'templates',
                                    type: 'GET',
                                }),
                            })];
                    case 1:
                        res = _c.sent();
                        if (!res.ok) return [3 /*break*/, 3];
                        return [4 /*yield*/, res.json()];
                    case 2:
                        body = _c.sent();
                        if (body) {
                            athleticsBody = body;
                            return [2 /*return*/, this.convertAthleticsOffers(athleticsBody)];
                        }
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, res.json()];
                    case 4:
                        error = _c.sent();
                        throw error;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        error_1 = _c.sent();
                        if (((_a = error_1 === null || error_1 === void 0 ? void 0 : error_1.message) === null || _a === void 0 ? void 0 : _a.length) || ((_b = error_1 === null || error_1 === void 0 ? void 0 : error_1.errorMessage) === null || _b === void 0 ? void 0 : _b.length)) {
                            return [2 /*return*/, undefined];
                        }
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    AthleticsApi.prototype.createLead = function (body) {
        return undefined;
    };
    AthleticsApi.prototype.intervalMapping = function (interval) {
        if (interval.includes('M')) {
            return 'MONTH';
        }
        if (interval.includes('T')) {
            return 'DAY';
        }
        if (interval.includes('Y')) {
            return 'YEAR';
        }
    };
    AthleticsApi.prototype.removeInterval = function (interval) {
        return +interval.replace(/\D+/, '');
    };
    AthleticsApi.prototype.convertAthleticsOffers = function (body) {
        var _this = this;
        var newTypes = body.map(function (a) {
            return ({
                id: a.id,
                name: a.name,
                terms: [
                    {
                        name: a.name,
                        id: a.id,
                        price: +a.rate / 100,
                        termUnit: _this.intervalMapping(a.repetition),
                        termValue: _this.removeInterval(a.repetition),
                        flatFees: [],
                        cancellationPeriod: _this.removeInterval(a.terminationTime),
                        cancellationPeriodUnit: _this.intervalMapping(a.terminationTime),
                        paymentFrequencyValue: _this.removeInterval(a.repetition),
                        paymentFrequencyUnit: _this.intervalMapping(a.repetition),
                    },
                ],
            });
        });
        return newTypes;
    };
    AthleticsApi.prototype.convertToAthleticsCustomer = function (body) {
        var _a;
        var customer = {
            name: body.customer.lastname + ', ' + body.customer.firstname,
            bic: body.customer.bankAccount.bic,
            iban: body.customer.bankAccount.iban,
            birthday: body.customer.dateOfBirth,
            city: body.customer.zipCode + ' ' + body.customer.city,
            email: body.customer.email,
            mobile: body.customer.telephone_mobile,
            contractBegin: ((_a = body === null || body === void 0 ? void 0 : body.contract) === null || _a === void 0 ? void 0 : _a.startDate) ||
                new Date().toISOString().replace(/T.*/, ''),
            street: body.customer.street + ' ' + body.customer.houseNumber,
            sepaGroup: 1,
            templateId: body.contract.rateBundleTermId,
        };
        return customer;
    };
    //   // This can be used e.g. to validate a voucher
    AthleticsApi.prototype.previewNewCustomer = function (body) {
        console.error('PREVIEW CUSTOMER NOT DEFINED FOR ATHLETICS API');
        return undefined;
    };
    AthleticsApi.prototype.validateVoucher = function (body) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                console.error('VOUCHERS NOT DEFINED FOR ATHLETICS API');
                return [2 /*return*/, undefined];
            });
        });
    };
    return AthleticsApi;
}());
exports.AthleticsApi = AthleticsApi;
