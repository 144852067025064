"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrackerService = void 0;
var leads_const_1 = require("../const/leads.const");
// utm_source=google&utm_medium=cpc&utm_campaign=spring_sale&utm_content=ad_variant_1
// utm_source=facebook&utm_medium=social&utm_campaign=product_launch&utm_content=video_ad
var TrackerService = /** @class */ (function () {
    function TrackerService() {
    }
    TrackerService.prototype.loadModule = function () {
        var _this = this;
        var firstVisit = localStorage.getItem(leads_const_1._FirstVisit);
        if (!firstVisit) {
            localStorage.setItem(leads_const_1._FirstVisit, new Date().toISOString());
        }
        $('form').each(function (_, formElement) {
            _this.addUTMParametersToForm($(formElement));
        });
    };
    TrackerService.prototype.addUTMParametersToForm = function (form) {
        var utmValues = this.getUTMValues();
        // Create and append input fields only if corresponding UTM parameters are available
        leads_const_1._UtmFields.forEach(function (field) {
            if (utmValues[field]) {
                form.append("<input type=\"hidden\" name=\"".concat(field, "_input\" id=\"").concat(field, "_input\" value=\"").concat(utmValues[field], "\">"));
            }
        });
    };
    TrackerService.prototype.getUTMValues = function () {
        var values = this.getUTMFromUrl();
        leads_const_1._UtmFields.forEach(function (utm) {
            if (values[utm]) {
                var oldValue = localStorage.getItem(utm);
                if (oldValue && values[utm] !== oldValue) {
                    //if no old values are stored we set them
                    localStorage.setItem(utm + '_last', values[utm]);
                }
                else {
                    //if no old values are stored we set them
                    localStorage.setItem(utm, values[utm]);
                }
            }
        });
        return values;
    };
    TrackerService.prototype.getUTMFromUrl = function () {
        var urlParams = new URLSearchParams(window.location.search);
        var values = {};
        leads_const_1._UtmFields.forEach(function (field) {
            values[field] = urlParams.get(field);
        });
        console.log('url values', values);
        return values;
    };
    return TrackerService;
}());
exports.TrackerService = TrackerService;
