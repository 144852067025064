"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReferralsModule = void 0;
var object_service_1 = require("../../../object-service");
var variables_const_1 = require("../../../variables.const");
var data_processing_service_1 = require("../services/data-processing.service");
var ReferralsModule = /** @class */ (function () {
    function ReferralsModule() {
        this.dataProcessing = object_service_1.ObjectService.getInstance(data_processing_service_1.DataProcessing);
    }
    ReferralsModule.prototype.loadModule = function (customer, moduleDiv) {
        var _this = this;
        if (customer) {
            this.dataProcessing.fillDataGet(moduleDiv, customer);
            // mapping
            var _ActionMapping = {
                copy: function () { return _this.copyReferralCode(customer.referralCode); },
                share: function () { return _this.shareReferralData(customer.referralCode); },
            };
            this.dataProcessing.mapDataAction(moduleDiv, _ActionMapping);
        }
        else {
            // TODO error handling
        }
    };
    ReferralsModule.prototype.copyReferralCode = function (code) {
        navigator.clipboard.writeText(code).then(function () {
            console.log('Text successfully copied to clipboard');
        }, function (err) {
            console.error('Error in copying text: ', err);
        });
    };
    ReferralsModule.prototype.shareReferralData = function (code) {
        if (navigator.share) {
            navigator
                .share({
                title: "".concat(variables_const_1._settings.tenantKey, " referral"),
                text: "Want to come train with me at ".concat(variables_const_1._settings.tenantKey, "? Use my referral code \"").concat(code, "\" to register!\nhttps://technogym-sso-testing.webflow.io/referal"), // TODO change url
            })
                .then(function () { return console.log('Share was successful.'); })
                .catch(function (error) { return console.log('Sharing failed', error); });
        }
        else {
            console.log('Web Share API is not supported in your browser.');
        }
    };
    return ReferralsModule;
}());
exports.ReferralsModule = ReferralsModule;
