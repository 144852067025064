"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AmendmentType = void 0;
var AmendmentType;
(function (AmendmentType) {
    AmendmentType["Contact"] = "contact-data";
    AmendmentType["Address"] = "address-data";
    AmendmentType["Master"] = "master-data";
    AmendmentType["Payment"] = "payment-data";
})(AmendmentType = exports.AmendmentType || (exports.AmendmentType = {}));
