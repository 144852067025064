"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StripePayment = void 0;
var stripe_js_1 = require("@stripe/stripe-js");
var helper_1 = require("../../helper");
var object_service_1 = require("../../object-service");
var variables_const_1 = require("../../variables.const");
var form_const_1 = require("./form.const");
var infrastructure_const_1 = require("../../const/infrastructure.const");
var error_handling_1 = require("../../error-handling");
var StripePayment = /** @class */ (function () {
    function StripePayment() {
        var _a;
        this.helper = object_service_1.ObjectService.getInstance(helper_1.Helper);
        this.errorHandling = object_service_1.ObjectService.getInstance(error_handling_1.ErrorHandling);
        this.magiclinePaymentChoice = 'STRIPE';
        this.paymentElementId = 'stripe-payment-container';
        this.hideTabs = (_a = variables_const_1._settings === null || variables_const_1._settings === void 0 ? void 0 : variables_const_1._settings.stripe) === null || _a === void 0 ? void 0 : _a.customTabs;
    }
    StripePayment.prototype.loadModule = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, idNode;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (variables_const_1.environment === 'dev') {
                            console.error('You use default stripe component without custom tabs if you want to add them please add customTabs in your stripe settings object');
                        }
                        if (!variables_const_1._settings.stripe.stripeKey) return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, (0, stripe_js_1.loadStripe)(variables_const_1._settings === null || variables_const_1._settings === void 0 ? void 0 : variables_const_1._settings.stripe.stripeKey, {
                                stripeAccount: variables_const_1._settings.stripe.accountId,
                            })];
                    case 1:
                        _a.stripe = _b.sent();
                        idNode = document.querySelector('#' + this.paymentElementId);
                        if (idNode) {
                            this.createWrappers(idNode);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        console.error('No stripe key defined please use in settings obj');
                        _b.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    StripePayment.prototype.createWrappers = function (node, paymentMethodOrder) {
        var _this = this;
        var _a, _b;
        var form = document.createElement('form');
        var cardWrapper = document.createElement('div');
        form.appendChild(cardWrapper);
        if (this.hideTabs === true) {
            form.style.top = '-65px';
            form.style.position = 'relative';
            cardWrapper.style.overflow = 'hidden';
            var hideWrapper = document.createElement('div');
            hideWrapper.className = 'hide-wrapper';
            hideWrapper.appendChild(form);
            node.appendChild(hideWrapper);
        }
        else {
            node.appendChild(form);
        }
        if ($(form_const_1.MAPPINGS.paymentChoiceButton.id).length) {
            var paymentButtons = $(form_const_1.MAPPINGS.paymentChoiceButton.id);
            this.changePaymentMethod(paymentButtons[0]);
            paymentButtons.off('click');
            paymentButtons.on('click', function (event) {
                if (event === null || event === void 0 ? void 0 : event.currentTarget) {
                    var payment = _this.changePaymentMethod(event.currentTarget);
                    if (payment.type !== 'magicline' && _this.hideTabs === true) {
                        _this.reshuffleStripePaymentOrder(payment);
                    }
                }
            });
        }
        if (((_a = variables_const_1._settings === null || variables_const_1._settings === void 0 ? void 0 : variables_const_1._settings.stripe) === null || _a === void 0 ? void 0 : _a.priceId) ||
            (((_b = variables_const_1._settings.stripe.upfront) === null || _b === void 0 ? void 0 : _b.length) &&
                window.location.href.includes('debug=test'))) {
            this.upfrontPayment(cardWrapper, paymentMethodOrder);
        }
        else {
            this.reloadStripeElement(cardWrapper, paymentMethodOrder);
        }
    };
    StripePayment.prototype.upfrontPayment = function (formToAttach, paymentMethodOrder) {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function () {
            var response, clientSecret, appearance;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        if (this.paymentElement) {
                            this.paymentElement.unmount();
                        }
                        if (this.stripeExpressElements) {
                            this.stripeExpressElements.unmount();
                        }
                        return [4 /*yield*/, fetch(infrastructure_const_1._CloudFunctions.stripe + '/upfront-payment', {
                                headers: { 'Content-Type': 'application/json' },
                                method: 'POST',
                                body: JSON.stringify({
                                    tenantKey: variables_const_1._settings.tenantKey,
                                    clientKey: (_a = variables_const_1._settings === null || variables_const_1._settings === void 0 ? void 0 : variables_const_1._settings.stripe) === null || _a === void 0 ? void 0 : _a.stripeKey,
                                    accountId: variables_const_1._settings.stripe.accountId,
                                    priceId: ((_b = variables_const_1._settings === null || variables_const_1._settings === void 0 ? void 0 : variables_const_1._settings.stripe) === null || _b === void 0 ? void 0 : _b.priceId) || 'price_1PCMeIQ20HCy7ZcIAo508Ha0',
                                }),
                            })];
                    case 1:
                        response = _e.sent();
                        return [4 /*yield*/, response.json()];
                    case 2:
                        clientSecret = (_e.sent()).clientSecret;
                        appearance = __assign(__assign({}, (((_c = variables_const_1._settings === null || variables_const_1._settings === void 0 ? void 0 : variables_const_1._settings.stripe) === null || _c === void 0 ? void 0 : _c.appearance) || {})), { theme: ((_d = variables_const_1._settings === null || variables_const_1._settings === void 0 ? void 0 : variables_const_1._settings.stripe) === null || _d === void 0 ? void 0 : _d.theme) || 'night' });
                        this.stripeElements = this.stripe.elements({
                            clientSecret: clientSecret,
                            appearance: appearance,
                        });
                        this.paymentElement = this.stripeElements.create('payment', {
                            layout: {
                                type: 'tabs',
                                defaultCollapsed: false,
                                radios: true,
                                spacedAccordionItems: false,
                            },
                            paymentMethodOrder: paymentMethodOrder || [],
                        });
                        this.paymentElement.mount(formToAttach);
                        return [2 /*return*/];
                }
            });
        });
    };
    StripePayment.prototype.reshuffleStripePaymentOrder = function (payment) {
        var tab = document.querySelector('.w-tab-pane[data-w-tab="' + payment.original + '"]');
        tab.innerHTML = '';
        if (tab) {
            this.createWrappers(tab, [
                payment.value,
            ]);
        }
    };
    StripePayment.prototype.changePaymentMethod = function (domElement) {
        var payment = this.helper.determinePaymentMethod(domElement);
        this.magiclinePaymentChoice =
            payment.type !== 'magicline' ? 'STRIPE' : payment.value;
        return payment;
    };
    StripePayment.prototype.reloadStripeElement = function (formToAttach, paymentMethodOrder) {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function () {
            var response, clientSecret, appearance, expressCheckoutOptions, elements, expressContainer, div;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        if (this.paymentElement) {
                            this.paymentElement.unmount();
                        }
                        if (this.stripeExpressElements) {
                            this.stripeExpressElements.unmount();
                        }
                        return [4 /*yield*/, fetch(infrastructure_const_1._CloudFunctions.stripe + '/setup-intent', {
                                headers: { 'Content-Type': 'application/json' },
                                method: 'POST',
                                body: JSON.stringify({
                                    tenantKey: variables_const_1._settings.tenantKey,
                                    clientKey: (_a = variables_const_1._settings === null || variables_const_1._settings === void 0 ? void 0 : variables_const_1._settings.stripe) === null || _a === void 0 ? void 0 : _a.stripeKey,
                                    accountId: variables_const_1._settings.stripe.accountId,
                                }),
                            })];
                    case 1:
                        response = _e.sent();
                        return [4 /*yield*/, response.json()];
                    case 2:
                        clientSecret = (_e.sent()).clientSecret;
                        appearance = __assign(__assign({}, (((_b = variables_const_1._settings === null || variables_const_1._settings === void 0 ? void 0 : variables_const_1._settings.stripe) === null || _b === void 0 ? void 0 : _b.appearance) || {})), { theme: ((_c = variables_const_1._settings === null || variables_const_1._settings === void 0 ? void 0 : variables_const_1._settings.stripe) === null || _c === void 0 ? void 0 : _c.theme) || 'night' });
                        this.stripeElements = this.stripe.elements({
                            clientSecret: clientSecret,
                            appearance: appearance,
                        });
                        if (((_d = variables_const_1._settings === null || variables_const_1._settings === void 0 ? void 0 : variables_const_1._settings.stripe) === null || _d === void 0 ? void 0 : _d.express) &&
                            window.location.href.includes('debug=test')) {
                            expressCheckoutOptions = {
                                buttonType: {
                                    applePay: 'buy',
                                    googlePay: 'buy',
                                },
                            };
                            elements = this.stripe.elements({
                                mode: 'payment',
                                amount: 1099,
                                currency: 'usd',
                                appearance: appearance,
                            });
                            this.stripeExpressElements = elements.create('expressCheckout', expressCheckoutOptions);
                            expressContainer = document.createElement('div');
                            expressContainer.id = 'express-checkout';
                            div = document.querySelector('#' + this.paymentElementId);
                            div.appendChild(expressContainer);
                            this.stripeExpressElements.mount('#express-checkout');
                        }
                        this.paymentElement = this.stripeElements.create('payment', {
                            layout: {
                                type: 'tabs',
                                defaultCollapsed: false,
                                radios: true,
                                spacedAccordionItems: false,
                            },
                            wallets: { applePay: 'auto', googlePay: 'auto' },
                            paymentMethodOrder: paymentMethodOrder || [
                                'apple_pay',
                                'google_pay',
                                'card',
                                'klarna',
                            ],
                        });
                        this.paymentElement.mount(formToAttach);
                        return [2 /*return*/];
                }
            });
        });
    };
    StripePayment.prototype.startPayment = function (event, customer) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var res, paymentMethodId;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        event.preventDefault();
                        return [4 /*yield*/, this.stripe.confirmSetup({
                                elements: this.stripeElements,
                                redirect: 'if_required',
                            })];
                    case 1:
                        res = _c.sent();
                        if (res === null || res === void 0 ? void 0 : res.error) {
                            throw (_a = res === null || res === void 0 ? void 0 : res.error) === null || _a === void 0 ? void 0 : _a.message;
                        }
                        paymentMethodId = res.setupIntent.payment_method;
                        return [2 /*return*/, fetch(infrastructure_const_1._CloudFunctions.stripe + '/order', {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json' },
                                body: JSON.stringify({
                                    stripe: {
                                        accountId: variables_const_1._settings.stripe.accountId,
                                        paymentMethodId: paymentMethodId,
                                        tenantKey: variables_const_1._settings.tenantKey,
                                        clientKey: (_b = variables_const_1._settings === null || variables_const_1._settings === void 0 ? void 0 : variables_const_1._settings.stripe) === null || _b === void 0 ? void 0 : _b.stripeKey,
                                        studioId: customer.studioId,
                                        termId: customer.contract.rateBundleTermId,
                                        moduleIds: [],
                                        customerData: {
                                            email: customer.customer.email,
                                            name: customer.customer.firstname + ' ' + customer.customer.lastname,
                                            street: customer.customer.street,
                                            houseNumber: customer.customer.houseNumber,
                                            city: customer.customer.city,
                                            zipCode: customer.customer.zipCode,
                                            country: customer.customer.countryCode,
                                        },
                                    },
                                    magicline: customer,
                                }),
                            }).then(function (response) {
                                if (!response.ok) {
                                    throw new Error('Network response was not ok');
                                }
                                return response.text();
                            })];
                }
            });
        });
    };
    return StripePayment;
}());
exports.StripePayment = StripePayment;
