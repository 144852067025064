"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RecaptchaTokenService = void 0;
var RecaptchaTokenService = /** @class */ (function () {
    function RecaptchaTokenService() {
        // is public and for all domains the same
        this.sitekey = '6Ld5qKcgAAAAAMoywArOgIrC0lQ7NpYUTsF92PIC';
        this.loadReCaptcha();
    }
    // Method to load the reCAPTCHA library programmatically
    RecaptchaTokenService.prototype.loadReCaptcha = function () {
        if (typeof grecaptcha === 'undefined') {
            // Check if reCAPTCHA is not already loaded
            var script = document.createElement('script');
            script.src =
                'https://www.google.com/recaptcha/api.js?onload=onRecaptchaLoad&render=explicit';
            script.async = true;
            script.defer = true;
            document.head.appendChild(script);
            // Ensure the global callback is accessible
            window.onRecaptchaLoad = function () { };
        }
    };
    // Called to get token
    RecaptchaTokenService.prototype.renderReCaptcha = function (callback, containerId) {
        if (containerId === void 0) { containerId = 'recaptcha-token'; }
        grecaptcha.render(containerId, {
            sitekey: this.sitekey,
            callback: callback,
            size: 'invisible',
        });
    };
    RecaptchaTokenService.prototype.executeCaptcha = function () {
        grecaptcha.execute();
    };
    // Callback when reCAPTCHA is successfully solved
    RecaptchaTokenService.prototype.onSubmit = function (token) {
        console.log('Captcha solved, token:', token);
        // // Make the request to your endpoint with the token
        // this.submitToken(token);
    };
    // Submit the reCAPTCHA token to your endpoint
    RecaptchaTokenService.prototype.submitToken = function (token) {
        // Example endpoint call
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        var body = JSON.stringify({
            recaptchaToken: token,
            // Add other parameters as needed
        });
        // fetch(this.endpointUrl, {
        //   method: 'POST',
        //   headers: headers,
        //   body: body,
        // })
        //   .then((response) => response.json())
        //   .then((data) => console.log('Success:', data))
        //   .catch((error) => console.error('Error:', error));
    };
    return RecaptchaTokenService;
}());
exports.RecaptchaTokenService = RecaptchaTokenService;
