"use strict";
// SINGLETON DESIGN PATTERN TO HAVE ONLY ONE INSTANCE PER CLASS
Object.defineProperty(exports, "__esModule", { value: true });
exports.ObjectService = void 0;
var ObjectService = exports.ObjectService = /** @class */ (function () {
    function ObjectService() {
    }
    // private constructor(){
    // }
    ObjectService.getInstance = function (classType) {
        var className = classType.name;
        if (!ObjectService.instances[className]) {
            ObjectService.instances[className] = new classType();
        }
        return ObjectService.instances[className];
    };
    ObjectService.instances = {};
    return ObjectService;
}());
