"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeadService = void 0;
var leads_const_1 = require("../const/leads.const");
var mappings_const_1 = require("../section/lead-form/mappings.const");
var variables_const_1 = require("../variables.const");
var LeadService = /** @class */ (function () {
    function LeadService() {
    }
    LeadService.prototype.getLeadFromLocalStorage = function () {
        if (localStorage.getItem('leadhash')) {
            var lead_1 = {};
            for (var key in mappings_const_1._LeadFormMappings) {
                var value = localStorage.getItem(mappings_const_1._LeadFormMappings[key].localStorageKey);
                if (value) {
                    lead_1[key] = value;
                }
            }
            leads_const_1._UtmFields.forEach(function (utm) {
                if (localStorage.getItem(utm)) {
                    lead_1[utm] = localStorage.getItem(utm);
                }
                if (localStorage.getItem(utm + '_last')) {
                    lead_1[utm + '_last'] = localStorage.getItem(utm + '_last');
                }
            });
            if (localStorage.getItem(leads_const_1._FirstVisit)) {
                lead_1.firstVisit = new Date(localStorage.getItem(leads_const_1._FirstVisit));
            }
            if (localStorage.getItem('leadhash')) {
                lead_1.leadhash = localStorage.getItem('leadhash');
            }
            if (localStorage.getItem('Location')) {
                lead_1.studioId = +localStorage.getItem('Location');
            }
            if (!(lead_1 === null || lead_1 === void 0 ? void 0 : lead_1.tenant)) {
                lead_1.tenant = variables_const_1._settings.tenantKey;
            }
            return lead_1;
        }
        return undefined;
    };
    return LeadService;
}());
exports.LeadService = LeadService;
