"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports._ProfileEditMapping = void 0;
exports._ProfileEditMapping = {
    profileUpload: {
        id: 'profile-upload',
        required: true,
    },
    profileUpdateSubmit: {
        id: 'profile-update-submit',
        required: true,
    },
    openWebcamButton: {
        id: 'open-webcam',
        required: false,
    },
    takePhotoButton: {
        id: 'take-photo',
        required: false,
    },
    errorMessage: {
        id: 'upload-error-message',
        required: false,
    },
    successMessage: {
        id: 'upload-success-message',
        required: false,
    },
    loadingScreen: {
        id: 'upload-loading-screen',
        required: false,
    },
};
