"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FitposAPIMiddleware = void 0;
var fitpos_api_1 = require("../../../api/fitpos-api");
var object_service_1 = require("../../../object-service");
var amendment_model_1 = require("../models/amendment.model");
var FitposAPIMiddleware = /** @class */ (function () {
    function FitposAPIMiddleware() {
        this.fitposAPI = object_service_1.ObjectService.getInstance(fitpos_api_1.FitposApi);
    }
    FitposAPIMiddleware.prototype.postAmendments = function (updatedProperties, customer) {
        return __awaiter(this, void 0, void 0, function () {
            var contactAmendment, addressAmendment, masterAmendment, paymentAmendment, mapping, amendments;
            return __generator(this, function (_a) {
                contactAmendment = {};
                addressAmendment = this.extractAddress(customer);
                masterAmendment = this.extractMasterData(customer);
                paymentAmendment = {};
                mapping = [
                    {
                        keys: ["email", "phonePrivate", "phonePrivatMobile", "phoneBusiness", "phoneBusinessMobile"],
                        type: amendment_model_1.AmendmentType.Contact,
                        body: contactAmendment
                    },
                    {
                        keys: ["street", "houseNumber", "zipCode", "city", "countryCode"],
                        type: amendment_model_1.AmendmentType.Address,
                        body: addressAmendment
                    },
                    {
                        keys: ["firstName", "lastName", "gender", "dateOfBirth", "customerTitle"],
                        type: amendment_model_1.AmendmentType.Master,
                        body: masterAmendment
                    },
                    {
                        keys: ["accountHolder", "bankName", "iban", "bic", "signature"],
                        type: amendment_model_1.AmendmentType.Payment,
                        body: paymentAmendment
                    }
                ];
                amendments = [];
                Object.keys(updatedProperties).forEach(function (key) {
                    var matchFound = false;
                    mapping.forEach(function (element) {
                        if (element.keys.includes(key)) {
                            matchFound = true;
                            if (!amendments.map(function (amendment) { return amendment.type; }).includes(element.type)) {
                                amendments.push({
                                    type: element.type,
                                    body: element.body
                                });
                            }
                            element.body[key] = updatedProperties[key];
                        }
                    });
                    if (!matchFound)
                        throw new Error("Can't update property " + key + ", invalid name (If applicable use countryCode, not country)");
                });
                this.fitposAPI.postCustomerDataAmendment(customer.id, amendments);
                return [2 /*return*/];
            });
        });
    };
    FitposAPIMiddleware.prototype.extractAddress = function (customer) {
        return {
            street: customer.street,
            houseNumber: customer.houseNumber,
            zipCode: customer.zipCode,
            city: customer.city,
            countryCode: customer.country
        };
    };
    FitposAPIMiddleware.prototype.extractMasterData = function (customer) {
        return {
            firstName: customer.firstName,
            lastName: customer.lastName,
            gender: customer.gender,
            dateOfBirth: customer.dateOfBirth,
            customerTitle: customer.customerTitle
        };
    };
    return FitposAPIMiddleware;
}());
exports.FitposAPIMiddleware = FitposAPIMiddleware;
