"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CancelContractsModule = void 0;
var fitpos_api_1 = require("../../../api/fitpos-api");
var object_service_1 = require("../../../object-service");
var data_processing_service_1 = require("../services/data-processing.service");
var my_contracts_module_1 = require("./my-contracts.module");
var CancelContractsModule = /** @class */ (function () {
    function CancelContractsModule() {
        this.fitposAPI = object_service_1.ObjectService.getInstance(fitpos_api_1.FitposApi);
        this.dataProcessing = object_service_1.ObjectService.getInstance(data_processing_service_1.DataProcessing);
    }
    CancelContractsModule.prototype.loadModule = function (customer, moduleDiv) {
        return __awaiter(this, void 0, void 0, function () {
            var _ActionMapping;
            var _this = this;
            return __generator(this, function (_a) {
                if (customer) {
                    _ActionMapping = {
                        submit: function (event) { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, this.submitCancelation(event, customer)];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); },
                    };
                    // confirm button
                    this.dataProcessing.mapDataAction(moduleDiv, _ActionMapping);
                }
                else {
                    // TODO behaviour if customer couldn't be retrieved
                }
                return [2 /*return*/];
            });
        });
    };
    CancelContractsModule.prototype.submitCancelation = function (event, customer) {
        return __awaiter(this, void 0, void 0, function () {
            var contractId, redirect, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        contractId = $("[data-module='my-contracts']").attr(my_contracts_module_1.MyContractsModule.selected);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        if (!contractId) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.fitposAPI.postContractCancelation(customer.id, {
                                contractId: parseInt(contractId),
                                cancelationDate: this.getCurrentDate(),
                                cancelationReasonId: 0, //TODO change
                            })];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        if (event.target) {
                            redirect = event.target.getAttribute('data-redirect');
                            window.location.href = redirect;
                        }
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _a.sent();
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    CancelContractsModule.prototype.getCurrentDate = function () {
        var today = new Date();
        var year = today.getFullYear();
        // Get the month and add 1 because JavaScript counts months from 0 to 11
        var month = today.getMonth() + 1;
        var day = today.getDate();
        // Pad the month and day with leading zeros if necessary
        var formattedMonth = month < 10 ? "0".concat(month) : month;
        var formattedDay = day < 10 ? "0".concat(day) : day;
        return "".concat(year, "-").concat(formattedMonth, "-").concat(formattedDay);
    };
    return CancelContractsModule;
}());
exports.CancelContractsModule = CancelContractsModule;
