"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Helper = void 0;
var form_const_1 = require("./section/finish/form.const");
var variables_const_1 = require("./variables.const");
var Helper = /** @class */ (function () {
    function Helper() {
        this.stylesAdded = false;
        this.addDocumentStyles();
    }
    Helper.prototype.replaceSpecialCharacter = function (input) {
        return input
            .replace(/ä/g, 'ae')
            .replace(/ö/g, 'oe')
            .replace(/ü/g, 'ue')
            .replace(/Ä/g, 'Ae')
            .replace(/Ö/g, 'Oe')
            .replace(/Ü/g, 'Ue')
            .replace(/ß/g, 'ss')
            .replace(/é/g, 'e')
            .replace(/è/g, 'e')
            .replace(/á/g, 'a')
            .replace(/ć/g, 'c')
            .replace(/ł/g, 'l')
            .replace(/Ł/g, 'L');
    };
    Helper.prototype.determinePaymentMethod = function (paymentTabs) {
        var paymentChoice = paymentTabs
            .querySelector('.w--current')
            .getAttribute('data-w-tab');
        var original = paymentChoice;
        paymentChoice = paymentChoice.toLowerCase();
        var magicLineMappings = {
            sepa: 'DIRECT_DEBIT',
            überweisung: 'DIRECT_DEBIT',
            ueberweisung: 'DIRECT_DEBIT',
            // kreditkarte: 'CREDIT_CARD',
        };
        var stripeMappings = {
            klarna: 'klarna',
            paypal: 'paypal',
            kreditkarte: 'card',
            ideal: 'ideal',
            sofort: 'sofort',
        };
        if (magicLineMappings[paymentChoice]) {
            return {
                original: original,
                value: magicLineMappings[paymentChoice],
                type: 'magicline',
            };
        }
        else if (stripeMappings[paymentChoice]) {
            return {
                original: original,
                value: stripeMappings[paymentChoice],
                type: 'stripe',
            };
        }
        return { original: original, value: paymentChoice, type: 'unknown' };
    };
    Helper.prototype.printLocalStorage = function () {
        // Localstorage auslesen
        for (var i = 0; i < localStorage.length; i++) {
            var key = localStorage.key(i);
            var value = localStorage.getItem(key);
            console.log(key + ': ' + value);
        }
    };
    Helper.prototype.roundDecimals = function (toRound, floatingPoints) {
        var factor = Math.pow(10, floatingPoints);
        return Math.round(toRound * factor) / factor;
    };
    Helper.prototype.getTodaysDate = function () {
        var today = new Date();
        var year = today.getFullYear();
        var month = today.getMonth() + 1;
        var date = today.getDate();
        return (year +
            '-' +
            (month < 10 ? '0' + month : month) +
            '-' +
            (date < 10 ? '0' + date : date));
    };
    Helper.prototype.getBirthdayDate = function (birthdayDate) {
        var splittedDate = birthdayDate.split('/');
        var year = splittedDate[0];
        var month = splittedDate[1];
        var day = splittedDate[2];
        if (Number(month) < 10) {
            month = '0' + month;
        }
        if (Number(day) < 10) {
            day = '0' + day;
        }
        return year + '-' + month + '-' + day;
    };
    Helper.prototype.removeErrorFromFormField = function (formField) {
        $(formField.id).css('border', '');
        $('#error-' + formField.id.replace('#', '')).remove();
    };
    Helper.prototype.addErrorToFormField = function (formField, errorMessage) {
        this.removeErrorFromFormField(formField);
        $(formField.id).after($("<span \n            style=\"color:red\" \n            class=\"form-error\" \n            id=\"error-".concat(formField.id.replace('#', ''), "\" \n            data-link=\"").concat(formField.id, "\">\n          *").concat(errorMessage || form_const_1.CUSTOMERRORS.fieldRequired, "\n        </span>")));
        if ($(formField.id).is(':input')) {
            $(formField.id).css('border', 'red 1px solid');
        }
    };
    Helper.prototype.scrollToFirstErrorField = function () {
        if ($('.form-error').length) {
            var link = $('.form-error:visible').first().attr('data-link');
            if (link) {
                this.scrollToPageElementOffset(link);
            }
        }
    };
    Helper.prototype.scrollToPageElementOffset = function (elementIdentifier, offset, duration) {
        if (offset === void 0) { offset = 180; }
        if (duration === void 0) { duration = 750; }
        var pageScroll = document.documentElement.scrollTop || document.body.scrollTop;
        console.log('helper pageScroll', elementIdentifier, offset, duration, pageScroll);
        if ($(elementIdentifier).length &&
            (pageScroll > offset + 50 || pageScroll < offset - 50)) {
            $('html, body').animate({
                scrollTop: $(elementIdentifier).offset().top - offset,
            }, duration);
        }
    };
    /**
     * Tries to get the latest data from either dom element or localstorage based on a mapping object
     * @param mappingObject
     * @returns value or undefined
     */
    Helper.prototype.getPersistedData = function (mappingObject) {
        var _a;
        var value = (_a = $(mappingObject['id']).val()) !== null && _a !== void 0 ? _a : localStorage.getItem(mappingObject['localStorageKey']);
        if (value !== undefined && value !== null) {
            return String(value);
        }
        return undefined;
    };
    Helper.prototype.deleteLoadingScreen = function () {
        $('#full-screen-loading').remove();
        $('.lds-ring').remove();
    };
    Helper.prototype.hideRefObjects = function (refObjectIds) {
        for (var _i = 0, _a = __spreadArray([
            '#refObjectId'
        ], refObjectIds.filter(function (a) { return a; }), true); _i < _a.length; _i++) {
            var refObjectId = _a[_i];
            var refObj = $(refObjectId);
            if (refObj.length) {
                refObj.css('cssText', 'display: none !important');
            }
        }
    };
    /**
     * Fullscreen loading animation
     * @param infoText
     */
    Helper.prototype.createLoadingScreen = function (infoText) {
        this.addDocumentStyles();
        if ($('#full-screen-loading').length === 0) {
            var loader = $('<div id="full-screen-loading"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>');
            $('body').append(loader);
        }
    };
    Helper.prototype.getLoader = function () {
        return $('<div class="lds-ring lds-ring-black"><div></div><div></div><div></div><div></div></div>');
    };
    Helper.prototype.addDocumentStyles = function () {
        if (this.stylesAdded === false) {
            $('<style>')
                .prop('type', 'text/css')
                .html("\n        #full-screen-loading{\n            display: flex;\n            justify-content: center;\n            align-items: center;\n            z-index:10000;\n            position:fixed;\n            top:0px;left:0px;\n            width:100vw;\n            height:100vh;\n            background:rgba(0,0,0,0.7);\n            backdrop-filter: blur(3px);\n        }\n        .lds-ring-black {\n            margin: 0px auto;\n            display: block;\n            margin-bottom:50px;\n        }\n        .lds-ring-black div {\n            border: 8px solid #222 !important;\n            border-color: #222 transparent transparent transparent !important;\n        }\n        .lds-ring {\n            display: inline-block;\n            position: relative;\n            width: 80px;\n            height: 80px;\n        }\n        .lds-ring div {\n            box-sizing: border-box;\n            display: block;\n            position: absolute;\n            width: 64px;\n            height: 64px;\n            margin: 8px;\n            border: 8px solid #fff;\n            border-radius: 50%;\n            animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;\n            border-color: #fff transparent transparent transparent;\n        }\n        .lds-ring div:nth-child(1) {\n            animation-delay: -0.45s;\n        }\n            .lds-ring div:nth-child(2) {\n            animation-delay: -0.3s;\n        }\n        .lds-ring div:nth-child(3) {\n            animation-delay: -0.15s;\n        }\n        @keyframes lds-ring {\n            0% {\n                transform: rotate(0deg);\n            }\n            100% {\n                transform: rotate(360deg);\n            }\n        }\n            ")
                .appendTo('head');
            this.stylesAdded = true;
        }
    };
    /**
     * Clones and modifies DOM object
     * @param attributes object array with the attributes the cloned object has. [{data-key: 'e.g.'}]
     * @param refObjectId #<<custom-id>> overwrites the default refobjectid
     * @returns cloned domObject
     */
    Helper.prototype.createClonesForRefObjectId = function (attributes, refObjectId, webflowSupport) {
        var domObject = $(refObjectId || '#' + (variables_const_1._settings === null || variables_const_1._settings === void 0 ? void 0 : variables_const_1._settings.refObjectId));
        if (domObject.length > 0) {
            //move clone to save space
            var copiedObject_1 = domObject.clone(true, true);
            for (var _i = 0, attributes_1 = attributes; _i < attributes_1.length; _i++) {
                var attribute = attributes_1[_i];
                copiedObject_1.attr(attribute.key, attribute.value);
            }
            copiedObject_1.attr('data-clone', 'true');
            domObject.get()[0].style.cssText += 'display: none !important';
            copiedObject_1.css({ display: '' });
            // copiedObject.css('display', 'flex');
            copiedObject_1.removeAttr('id');
            if (webflowSupport === true) {
                copiedObject_1.find('.w-dropdown-toggle').on('click', function () {
                    copiedObject_1.find('.w-dropdown-toggle').toggleClass('w--open');
                    copiedObject_1.find('.w-dropdown-list').toggleClass('w--open');
                });
            }
            return copiedObject_1;
        }
    };
    Helper.prototype.deleteClones = function () {
        $('[data-module-key][data-clone="true"]').remove();
    };
    Helper.prototype.get$Val = function (mappingObject) {
        var inputData = this.getPersistedData(mappingObject);
        if ((mappingObject === null || mappingObject === void 0 ? void 0 : mappingObject.type) === 'checkbox') {
            inputData = $(mappingObject['id']).is(':checked') ? 'true' : undefined;
        }
        if (inputData === undefined || inputData.length === 0) {
            //no data available default error
            if (mappingObject.required) {
                this.addErrorToFormField(mappingObject);
            }
            //prevent errors by using empty string
            return '';
        }
        else {
            if (mappingObject === null || mappingObject === void 0 ? void 0 : mappingObject.regex) {
                var regex = new RegExp(mappingObject.regex, 'g');
                if (regex.test(inputData) === false) {
                    this.addErrorToFormField(mappingObject, mappingObject.regexError);
                    return '';
                }
            }
            this.removeErrorFromFormField(mappingObject);
            return inputData;
        }
    };
    Helper.prototype.earthDistance = function (lat1, lon1, lat2, lon2, unit) {
        if (unit === void 0) { unit = 'K'; }
        if (lat1 == lat2 && lon1 == lon2) {
            return 0;
        }
        else {
            var radlat1 = (Math.PI * lat1) / 180;
            var radlat2 = (Math.PI * lat2) / 180;
            var theta = lon1 - lon2;
            var radtheta = (Math.PI * theta) / 180;
            var dist = Math.sin(radlat1) * Math.sin(radlat2) +
                Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
            if (dist > 1) {
                dist = 1;
            }
            dist = Math.acos(dist);
            dist = (dist * 180) / Math.PI;
            dist = dist * 60 * 1.1515;
            if (unit == 'K') {
                dist = dist * 1.609344;
            }
            if (unit == 'N') {
                dist = dist * 0.8684;
            }
            return dist;
        }
    };
    Helper.prototype.generateHash = function (str, seed) {
        if (seed === void 0) { seed = 0; }
        var h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
        for (var i = 0, ch = void 0; i < str.length; i++) {
            ch = str.charCodeAt(i);
            h1 = Math.imul(h1 ^ ch, 2654435761);
            h2 = Math.imul(h2 ^ ch, 1597334677);
        }
        h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
        h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
        h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
        h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);
        return String(4294967296 * (2097151 & h2) + (h1 >>> 0));
    };
    return Helper;
}());
exports.Helper = Helper;
