"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeadForm = void 0;
var api_wrapper_1 = require("../../api/api-wrapper");
var manychat_api_1 = require("../../api/manychat.api");
var leads_const_1 = require("../../const/leads.const");
var element_generator_1 = require("../../element-generator");
var error_handling_1 = require("../../error-handling");
var helper_1 = require("../../helper");
var object_service_1 = require("../../object-service");
var helper_const_1 = require("../../services/helper.const");
var tracker_service_1 = require("../../services/tracker.service");
var variables_const_1 = require("../../variables.const");
var form_const_1 = require("../finish/form.const");
var send_email_service_1 = require("../finish/services/send-email.service");
var mappings_const_1 = require("./mappings.const");
var LeadForm = /** @class */ (function () {
    function LeadForm() {
        this.elementGenerator = object_service_1.ObjectService.getInstance(element_generator_1.ElementGenerator);
        this.helper = object_service_1.ObjectService.getInstance(helper_1.Helper);
        this.errorHandling = object_service_1.ObjectService.getInstance(error_handling_1.ErrorHandling);
        this.sendEmailService = object_service_1.ObjectService.getInstance(send_email_service_1.SendEmailService);
        this.apiWrapper = object_service_1.ObjectService.getInstance(api_wrapper_1.ApiWrapper);
        this.manyChatApi = object_service_1.ObjectService.getInstance(manychat_api_1.ManyChatApi);
        this.trackerService = object_service_1.ObjectService.getInstance(tracker_service_1.TrackerService);
    }
    LeadForm.prototype.createLead = function (lead) {
        return __awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.apiWrapper.createLead(lead)];
                    case 1:
                        response = _a.sent();
                        if (response) {
                            if (response === null || response === void 0 ? void 0 : response.uuid) {
                                localStorage.setItem('leaduuid', String(response.uuid));
                            }
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        console.error('SEVER ERROR HAPPENED', e_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    LeadForm.prototype.loadModule = function () {
        var _this = this;
        this.prepareEmployeeView();
        this.preparePhoneField();
        this.elementGenerator.createElements();
        var submitButton = $(mappings_const_1._LeadFormButtons.submitButton.id);
        //Submit the form
        if (submitButton === null || submitButton === void 0 ? void 0 : submitButton.length) {
            submitButton.on('click', function () { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.sendForm()];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            }); });
        }
        //if we have a birthday collection field
        [
            $(form_const_1.MAPPINGS.yearOfBirth.id),
            $(form_const_1.MAPPINGS.monthOfBirth.id),
            $(form_const_1.MAPPINGS.dayOfBirth.id),
        ].forEach(function (a) {
            return a.on('change', function () {
                var userAge = _this.determineBirthDate();
                if (userAge === null || userAge === void 0 ? void 0 : userAge.age) {
                    localStorage.setItem('age', String(userAge.age));
                    localStorage.setItem('birthday', String(userAge.date));
                }
            });
        });
    };
    LeadForm.prototype.prepareEmployeeView = function () {
        if (variables_const_1._settings === null || variables_const_1._settings === void 0 ? void 0 : variables_const_1._settings.employeeVersion) {
            $('[data-employee="true"]').css('display', 'flex');
        }
    };
    LeadForm.prototype.sendForm = function () {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var lead, valid, key, result, leadhash, oldLeadHash, e_2;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        //if settings object injects the phoneprefix or by default +49
                        this.preparePhoneField();
                        lead = {};
                        valid = true;
                        for (key in mappings_const_1._LeadFormMappings) {
                            result = this.helper.get$Val(mappings_const_1._LeadFormMappings[key]);
                            if (result && mappings_const_1._LeadFormMappings[key].localStorageKey) {
                                //remove leading 0 on phone numbers
                                localStorage.setItem(mappings_const_1._LeadFormMappings[key].localStorageKey, result.trim());
                                if (key !== 'checkbox') {
                                    lead[key] = result.trim();
                                }
                            }
                            else if (mappings_const_1._LeadFormMappings[key].required) {
                                valid = false;
                                console.error('Invalid form');
                            }
                        }
                        if (!valid) return [3 /*break*/, 11];
                        //hash is to determine if user has changed sth (new lead)
                        lead.studioId = +localStorage.getItem('Location');
                        lead.phone =
                            localStorage.getItem('phonePrefix') +
                                localStorage.getItem(mappings_const_1._LeadFormMappings.phone.localStorageKey);
                        leadhash = this.helper.generateHash(JSON.stringify(lead));
                        oldLeadHash = localStorage.getItem('leadhash');
                        if (!(leadhash !== oldLeadHash)) return [3 /*break*/, 10];
                        this.helper.createLoadingScreen();
                        if (!!((_a = variables_const_1._settings === null || variables_const_1._settings === void 0 ? void 0 : variables_const_1._settings.leadForm) === null || _a === void 0 ? void 0 : _a.skipLeadCreation)) return [3 /*break*/, 3];
                        localStorage.setItem('leadhash', String(leadhash));
                        return [4 /*yield*/, this.createLead(lead)];
                    case 1:
                        _d.sent();
                        return [4 /*yield*/, this.createFirestoreLead(leadhash, lead)];
                    case 2:
                        _d.sent();
                        _d.label = 3;
                    case 3:
                        if (!((_b = variables_const_1._settings === null || variables_const_1._settings === void 0 ? void 0 : variables_const_1._settings.receiverMail) === null || _b === void 0 ? void 0 : _b.sendLeadEmail)) return [3 /*break*/, 7];
                        _d.label = 4;
                    case 4:
                        _d.trys.push([4, 6, , 7]);
                        return [4 /*yield*/, this.sendEmailService.sendEmail(variables_const_1._settings.receiverMail.sendLeadEmail, lead, 'Funnel lead')];
                    case 5:
                        _d.sent();
                        return [3 /*break*/, 7];
                    case 6:
                        e_2 = _d.sent();
                        return [3 /*break*/, 7];
                    case 7:
                        if (!((_c = variables_const_1._settings === null || variables_const_1._settings === void 0 ? void 0 : variables_const_1._settings.leadForm) === null || _c === void 0 ? void 0 : _c.manyChat)) return [3 /*break*/, 9];
                        return [4 /*yield*/, this.createManychat(lead)];
                    case 8:
                        _d.sent();
                        _d.label = 9;
                    case 9:
                        this.helper.deleteLoadingScreen();
                        _d.label = 10;
                    case 10:
                        //overwrite phone input field - shortly before redirecting to webflow forms
                        if ($(mappings_const_1._LeadFormMappings.phone.id).length) {
                            $(mappings_const_1._LeadFormMappings.phone.id).val(lead.phone);
                        }
                        //Next button we done here
                        $(mappings_const_1._LeadFormButtons.webflowSubmitButton.id).click();
                        if (variables_const_1._settings === null || variables_const_1._settings === void 0 ? void 0 : variables_const_1._settings.redirect) {
                            window.location.href = variables_const_1._settings.redirect;
                        }
                        _d.label = 11;
                    case 11: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Create an entry in our firestore lead collection
     * @param leadhash
     * @param lead
     */
    LeadForm.prototype.createFirestoreLead = function (leadhash, lead) {
        return __awaiter(this, void 0, void 0, function () {
            var firstVisit, utmFields_1, aov, membership, membershipName, e_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        firstVisit = localStorage.getItem(leads_const_1._FirstVisit);
                        utmFields_1 = {};
                        leads_const_1._UtmFields.forEach(function (a) {
                            if (localStorage.getItem(a)) {
                                utmFields_1[a] = localStorage.getItem(a);
                            }
                        });
                        aov = localStorage.getItem('aov')
                            ? JSON.parse(localStorage.getItem('aov') || '')
                            : undefined;
                        membership = localStorage.getItem('Memberships') || undefined;
                        membershipName = localStorage.getItem('membershipName') || undefined;
                        return [4 /*yield*/, this.apiWrapper.createFirestoreLead(__assign(__assign(__assign({}, lead), utmFields_1), { firstVisit: firstVisit ? new Date(firstVisit) : undefined, membership: membership, membershipName: membershipName, aov: aov, clv: (aov === null || aov === void 0 ? void 0 : aov.total)
                                    ? (0, helper_const_1._RoundUpToTwoDecimals)(aov.total * variables_const_1._AverageCustomerDuration)
                                    : undefined, leadhash: leadhash, version: '1.0', termDuration: localStorage.getItem('termDuration') || undefined, studioName: localStorage.getItem('StudioName') ||
                                    (variables_const_1._settings === null || variables_const_1._settings === void 0 ? void 0 : variables_const_1._settings.studioName) ||
                                    undefined, tenant: variables_const_1._settings.tenantKey, leadSource: $('[name="Leadsource"]:checked').val() }))];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        e_3 = _a.sent();
                        console.error('LEAD CREATION ERROR', e_3);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    /**
     *  Create a lead in manychat
     * @param lead
     */
    LeadForm.prototype.createManychat = function (lead) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var manyChatObject, leadResponse, e_4;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        manyChatObject = {
                            consent_phrase: 'Consent',
                            has_opt_in_email: true,
                            has_opt_in_sms: true,
                            gender: 'unknown',
                            first_name: lead.firstname,
                            last_name: lead.lastname,
                            whatsapp_phone: lead.phone,
                            name: lead.firstname + ' ' + lead.lastname,
                            tenantId: variables_const_1._settings.tenantKey,
                            studioId: lead.studioId,
                            manychatTenant: ((_a = variables_const_1._settings === null || variables_const_1._settings === void 0 ? void 0 : variables_const_1._settings.leadForm) === null || _a === void 0 ? void 0 : _a.manychatTenant) || variables_const_1._settings.tenantKey,
                        };
                        return [4 /*yield*/, this.manyChatApi.createLeadInManyChat(manyChatObject)];
                    case 1:
                        leadResponse = _b.sent();
                        if ((leadResponse === null || leadResponse === void 0 ? void 0 : leadResponse.success) && (leadResponse === null || leadResponse === void 0 ? void 0 : leadResponse.id)) {
                            localStorage.setItem('manychat-lead-id', leadResponse.id);
                        }
                        else if (leadResponse.error && variables_const_1.environment === 'dev') {
                            this.errorHandling.showErrorMessage(leadResponse.error);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        e_4 = _b.sent();
                        console.error('Is manychat / strato down??');
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    LeadForm.prototype.preparePhoneField = function () {
        var _a;
        var phonePrefix = ((_a = variables_const_1._settings === null || variables_const_1._settings === void 0 ? void 0 : variables_const_1._settings.leadForm) === null || _a === void 0 ? void 0 : _a.phonePrefix) || '+49';
        if (!localStorage.getItem('phonePrefix')) {
            localStorage.setItem('phonePrefix', phonePrefix);
        }
        if ($(mappings_const_1._LeadFormMappings.phone.id).length) {
            var result = String($(mappings_const_1._LeadFormMappings.phone.id).val());
            result = result.replace(/^0+/, '');
            if (phonePrefix) {
                var splitted = result.split(phonePrefix);
                result = splitted.length > 1 ? splitted[1] : splitted[0];
            }
            $(mappings_const_1._LeadFormMappings.phone.id).val(result);
        }
    };
    LeadForm.prototype.determineBirthDate = function () {
        var _a = [
            this.helper.get$Val(form_const_1.MAPPINGS.yearOfBirth),
            this.helper.get$Val(form_const_1.MAPPINGS.monthOfBirth),
            this.helper.get$Val(form_const_1.MAPPINGS.dayOfBirth),
        ], year = _a[0], month = _a[1], day = _a[2];
        if ((year === null || year === void 0 ? void 0 : year.length) && (month === null || month === void 0 ? void 0 : month.length) && (day === null || day === void 0 ? void 0 : day.length)) {
            var selectedBirthDate = this.helper.getBirthdayDate("".concat(year, "/").concat(month, "/").concat(day));
            var userAge = Math.floor((new Date().getTime() - new Date(selectedBirthDate).getTime()) /
                3.15576e10);
            return { age: userAge, date: selectedBirthDate };
        }
        return undefined;
    };
    return LeadForm;
}());
exports.LeadForm = LeadForm;
