"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports._COUNTRIES = void 0;
exports._COUNTRIES = [
    { name: 'Albania', prefix: '+355', isoCode: 'AL' },
    { name: 'Andorra', prefix: '+376', isoCode: 'AD' },
    { name: 'Austria', prefix: '+43', isoCode: 'AT' },
    { name: 'Belarus', prefix: '+375', isoCode: 'BY' },
    { name: 'Belgium', prefix: '+32', isoCode: 'BE' },
    { name: 'Bosnia and Herzegovina', prefix: '+387', isoCode: 'BA' },
    { name: 'Bulgaria', prefix: '+359', isoCode: 'BG' },
    { name: 'Croatia', prefix: '+385', isoCode: 'HR' },
    { name: 'Cyprus', prefix: '+357', isoCode: 'CY' },
    { name: 'Czech Republic', prefix: '+420', isoCode: 'CZ' },
    { name: 'Denmark', prefix: '+45', isoCode: 'DK' },
    { name: 'Estonia', prefix: '+372', isoCode: 'EE' },
    { name: 'Finland', prefix: '+358', isoCode: 'FI' },
    { name: 'France', prefix: '+33', isoCode: 'FR' },
    { name: 'Georgia', prefix: '+995', isoCode: 'GE' },
    { name: 'Germany', prefix: '+49', isoCode: 'DE' },
    { name: 'Greece', prefix: '+30', isoCode: 'GR' },
    { name: 'Hungary', prefix: '+36', isoCode: 'HU' },
    { name: 'Iceland', prefix: '+354', isoCode: 'IS' },
    { name: 'Ireland', prefix: '+353', isoCode: 'IE' },
    { name: 'Italy', prefix: '+39', isoCode: 'IT' },
    { name: 'Kosovo', prefix: '+383', isoCode: 'XK' },
    { name: 'Latvia', prefix: '+371', isoCode: 'LV' },
    { name: 'Liechtenstein', prefix: '+423', isoCode: 'LI' },
    { name: 'Lithuania', prefix: '+370', isoCode: 'LT' },
    { name: 'Luxembourg', prefix: '+352', isoCode: 'LU' },
    { name: 'Malta', prefix: '+356', isoCode: 'MT' },
    { name: 'Moldova', prefix: '+373', isoCode: 'MD' },
    { name: 'Monaco', prefix: '+377', isoCode: 'MC' },
    { name: 'Montenegro', prefix: '+382', isoCode: 'ME' },
    { name: 'Netherlands', prefix: '+31', isoCode: 'NL' },
    { name: 'North Macedonia', prefix: '+389', isoCode: 'MK' },
    { name: 'Norway', prefix: '+47', isoCode: 'NO' },
    { name: 'Poland', prefix: '+48', isoCode: 'PL' },
    { name: 'Portugal', prefix: '+351', isoCode: 'PT' },
    { name: 'Romania', prefix: '+40', isoCode: 'RO' },
    { name: 'Russia', prefix: '+7', isoCode: 'RU' },
    { name: 'San Marino', prefix: '+378', isoCode: 'SM' },
    { name: 'Serbia', prefix: '+381', isoCode: 'RS' },
    { name: 'Slovakia', prefix: '+421', isoCode: 'SK' },
    { name: 'Slovenia', prefix: '+386', isoCode: 'SI' },
    { name: 'Spain', prefix: '+34', isoCode: 'ES' },
    { name: 'Sweden', prefix: '+46', isoCode: 'SE' },
    { name: 'Switzerland', prefix: '+41', isoCode: 'CH' },
    { name: 'Turkey', prefix: '+90', isoCode: 'TR' },
    { name: 'Ukraine', prefix: '+380', isoCode: 'UA' },
    { name: 'United Kingdom', prefix: '+44', isoCode: 'GB' },
    { name: 'Vatican City', prefix: '+379', isoCode: 'VA' },
];
