"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PreviewCustomer = void 0;
exports.PreviewCustomer = {
    countryCode: 'DE',
    paymentChoice: 'DIRECT_DEBIT',
    dateOfBirth: '1991-01-16',
    firstname: 'example',
    lastname: 'example',
    zipCode: '64404',
    city: 'example',
    street: 'example str',
    houseNumber: '99',
    email: 'example@example.de',
    telephone_mobile: '0123456789',
    gender: 'MALE',
    bankAccount: {
        accountHolder: 'example',
        iban: 'DE02 1203 0000 0000 2020 51',
    },
};
